import React from "react";
import "../../styles/services.scss";
import ConditionsWeTreat from "./ConditionsWeTreat";
import Landing from "./Landing";

const ServicesPage = () => {
  return (
    <div className="services-page">
      <Landing />
      <ConditionsWeTreat />
    </div>
  );
};

export default ServicesPage;
