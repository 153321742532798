import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import illustration from "../../assets/img/hands.png";

const Landing = () => {
  const navigate = useNavigate();
  const scrollTo = useRef(null);

  useEffect(() => {
    Aos.init({ duration: 2400 });
  }, []);

  const serviceList = [
    {
      title: "Psychiatric Assessment",
      description:
        "Our comprehensive psychiatric assessments are designed to understand your unique needs and challenges. We believe in taking the time to listen to your concerns and collaboratively developing a personalized treatment plan.",
    },
    {
      title: "Medication Management",
      description:
        "Boring Psychiatric Services LLC specializes in prescribing and managing psychiatric medications. Medication management is a crucial component of mental health treatment, and our team is committed to finding the right balance for your well-being.",
    },
    {
      title: "Psychotherapy",
      description:
        "In addition to medication management, we offer psychotherapy services. Our therapeutic approaches are tailored to address your emotional and behavioral health, fostering a holistic and well-rounded treatment plan.",
    },
    {
      title: "Telehealth Services",
      description:
        "For your convenience, Boring Psychiatric Services LLC provides telehealth services, allowing you to access quality mental health care from the comfort of your own space.",
    },
  ];

  return (
    <>
      <section className="services-landing">
        <div className="content-container">
          <img src={illustration} alt="" className="illustration" />
          <div className="text-content">
            <h1 data-aos="fade">SERVICES</h1>
            <p data-aos="fade" data-aos-delay="500">
              Explore personalized mental health support tailored just for you.
              Discover how we can help on your well-being journey. Reach out to
              schedule an appointment and take the first step toward a
              healthier, happier you.
            </p>
            <button
              onClick={() => navigate("/contact")}
              data-aos="fade"
              data-aos-delay="1000"
            >
              Contact
            </button>
          </div>
        </div>
        <div
          className="down-btn"
          data-aos="fade"
          data-aos-delay="500"
          onClick={() => {
            scrollTo.current.scrollIntoView({ behavior: "smooth" });
          }}
        >
          <p>Read more</p>
          <span className="arrow">
            <span className="straight"></span>
            <span className="left"></span>
            <span className="right"></span>
          </span>
        </div>
      </section>
      <section className="services-services" ref={scrollTo}>
        <h2>Personalized Care Services</h2>
        <p className="intro" data-aos="fade" data-aos-delay="500">
          Discover a comprehensive array of mental health solutions tailored to
          your individual needs. Our services include Psychiatric Assessment,
          Medication Management, Psychotherapy, and convenient Telehealth
          Services, each designed to provide personalized care and support on
          your unique journey to well-being.
        </p>
        <ul className="list-container">
          {serviceList.map((service, serviceKey) => (
            <li key={serviceKey} className="card" data-aos="fade">
              <h3 className="title">{service.title}</h3>
              <p className="description">{service.description}</p>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Landing;
