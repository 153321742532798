import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

const AboutPatients = () => {
  const list = [
    {
      image: "",
      title: "Tailored for Every Stage of Life",
      content:
        "We offer personalized care for every life stage. From teens to seniors, our experienced team ensures tailored support at each step.",
    },
    {
      image: "",
      title: "Family-Friendly Environment",
      content:
        "All ages find a welcoming space. Our compassionate team ensures every individual and family feels understood.",
    },
    {
      image: "",
      title: "Privacy and Confidentiality",
      content:
        "Trust our experienced team for a safe, confidential space. Long-term patient trust underscores our commitment to your well-being.",
    },
    {
      image: "",
      title: "Collaborative Care Approach",
      content:
        "Experience collaborative care. Your voice and the voice of those who support you matter as we navigate your mental health journey together.",
    },
    {
      image: "",
      title: "Support for Specific Conditions",
      content:
        "We offer tailored support for various mental health conditions. Our experienced team provides personalized care for each unique need.",
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 2400 });
  }, []);

  return (
    <section className="about-patients">
      <span className="transition top"></span>
      <h2>Comprehensive Mental Health Care</h2>
      <ul className="list-container">
        {list.map((item, itemKey) => (
          <li key={itemKey} className="card" data-aos="fade">
            <img src={item.img} alt="" />
            <h3 className="title">{item.title}</h3>
            <p className="description">{item.content}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default AboutPatients;
