import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import AboutUsPage from "./components/aboutUs/AboutUsPage";
import ContactPage from "./components/contact/ContactPage";
import HomePage from "./components/home/HomePage";
import Navbar from "./components/navbar/Navbar";
import ServicesPage from "./components/services/ServicesPage";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about-us" element={<AboutUsPage />} />
        <Route exact path="/services" element={<ServicesPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
