import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import img4 from "../../assets/img/dark-dots.png";
import img2 from "../../assets/img/dark-knot.png";
import img5 from "../../assets/img/dark-loops.png";
import img3 from "../../assets/img/dark-mark.png";
import img6 from "../../assets/img/dark-opposites.png";
import img7 from "../../assets/img/dark-sharp.png";
import img1 from "../../assets/img/dark-wave.png";

const ConditionsWeTreat = () => {
  useEffect(() => {
    Aos.init({ duration: 2400 });
  }, []);

  const conditionList = [
    { name: "Depression", color: "#bde0fe", image: img3 },
    { name: "Bipolar disorder", color: "#f8ad9d", image: img6 },
    { name: "Anxiety Disorders", color: "#c9cba3", image: img1 },
    {
      name: "OCD",
      title: "(Obsessive-Compulsive Disorder)",
      color: "#f2e9e4",
      image: img2,
    },
    { name: "Sleep Disorders", color: "#b8bedd", image: img3 },
    { name: "Eating Disorders", color: "#f5cac3", image: img4 },
    { name: "Schizophrenia", color: "#eaf4f4", image: img6 },
    {
      name: "ADHD",
      title: "(Attention-Deficit/ Hyperactivity Disorder)",
      color: "#c9ada7",
      image: img7,
    },
    {
      name: "PTSD",
      title: "(Post-Traumatic Stress Disorder)",
      color: "#dee2ff",
      image: img1,
    },
    { name: "Autism", color: "#98c1d9", image: img5 },
    { name: "Oppositional Defiant Disorder", color: "#f38375", image: img6 },
    { name: "Trauma", color: "#cfdbd5", image: img3 },
    { name: "Medical Cannabis", color: "#b5c99a", image: img2 },
    { name: "Suboxone maintenance", color: "#f8f9fa", image: img5 },
    { name: "Substance abuse", color: "#fff6cc", image: img4 },
  ];

  return (
    <section className="services-conditions">
      <span className="transition"></span>
      <h2>Conditions we treat</h2>
      <ul className="condition-list">
        {conditionList.map((condition) => (
          <li title={condition.title ? condition.title : ""}>
            <span
              className="dot"
              style={{ "background-color": condition.color }}
            >
              {condition.image && <img src={condition.image} alt="" />}
            </span>
            <div className="content">
              <p className="title">
                {condition.name ? condition.name : condition}
              </p>
              <p className="description">
                {condition.title ? condition.title : ""}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <span className="transition" id="bottom"></span>
    </section>
  );
};

export default ConditionsWeTreat;
