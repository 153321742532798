import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useRef } from "react";
import license from "../../assets/img/license_FILL0_wght400_GRAD0_opsz24.svg";
import medstar from "../../assets/img/medstar.png";
import lifebridge from "../../assets/img/Lifebridge_Health_logo-square.png";
import certified from "../../assets/img/verified_user_FILL0_wght400_GRAD0_opsz24.svg";
import portrait from "../../assets/img/woman-portrait.png";
import master from "../../assets/img/workspace_premium_FILL0_wght400_GRAD0_opsz24.svg";
import "../../styles/aboutus.scss";
import Healthcare from "../healthcare";
import AboutPatients from "./AboutPatients";
import mensaIcon from "../../assets/img/mensa.png";

const AboutUsPage = () => {
  const scrollToCertifications = useRef(null);
  const scrollToPatient = useRef(null);

  const certificationList = [
    {
      icon: master,
      title: "Master's degree in Family Nurse Practitioner",
      content: (
        <>
          Graduating with the highest honors from{" "}
          <a href="https://www.sjcme.edu/" target="_blank" rel="noreferrer">
            St. Joseph's College of Maine
          </a>{" "}
          in 2016.
        </>
      ),
    },
    {
      icon: license,
      title: "Post-Masters Certificate as Psychiatric Nurse Practitioner",
      content: (
        <>
          From the{" "}
          <a href="https://www.uc.edu/" target="_blank" rel="noreferrer">
            University of Cincinnati
          </a>{" "}
          in 2019, once again graduating with top honors.
        </>
      ),
    },
    {
      icon: certified,
      title: (
        <>
          Credentialed with <br />
          <a
            href="https://www.lifebridgehealth.org/"
            target="_blank"
            rel="noreferrer"
          >
            Lifebridge Health
          </a>{" "}
          and{" "}
          <a
            href="https://www.medstarhealth.org/"
            target="_blank"
            rel="noreferrer"
          >
            Medstar
          </a>
        </>
      ),
      content: "",
      additional: [lifebridge, medstar],
    },
    {
      icon: mensaIcon,
      title: (
        <>
          Member of American{" "}
          <a
            href="https://maryland.us.mensa.org/"
            target="_blank"
            rel="noreferrer"
          >
            Mensa
          </a>{" "}
          since 1983
        </>
      ),
      content: "",
      id: "bigger-logo",
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 2400 });
  }, []);

  return (
    <div className="aboutus-page">
      <section className="about-us">
        <div className="top">
          <div className="text">
            <h1 data-aos="fade">ABOUT US</h1>
            <p data-aos="fade" data-aos-delay="500">
              Boring Psychiatric Services LLC is led by Gayle Blum, a
              board-certified Family and Psychiatric Nurse Practitioner with a
              passion for mental health care. With years of experience in
              private and hospital settings, Gayle Blum is dedicated to offering
              compassionate and evidence-based psychiatric services to
              individuals seeking support.
            </p>
          </div>
          <img src={portrait} alt="" className="portrait" />
          <span className="side"></span>
          <span className="transition"></span>
        </div>
        <div
          className="down-btn"
          data-aos="fade"
          data-aos-delay="500"
          onClick={() => {
            scrollToCertifications.current.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <p>Read more</p>
          <span className="arrow">
            <span className="straight"></span>
            <span className="left"></span>
            <span className="right"></span>
          </span>
        </div>
      </section>
      <section className="resume" ref={scrollToCertifications}>
        <span className="transition top"></span>
        <ul className="list">
          {certificationList.map((item, itemKey) => (
            <li
              className="certification"
              key={itemKey}
              id={item.id ? item.id : ""}
            >
              <img src={item.icon} alt="" />
              <h3 data-aos="fade">{item.title}</h3>
              <p data-aos="fade">{item.content}</p>
              {item.additional && (
                <div className="additional-info">
                  {item.additional.map((element) => (
                    <img src={element} alt="" />
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
        <div
          className="down-btn"
          onClick={() => {
            scrollToPatient.current.scrollIntoView({ behavior: "smooth" });
          }}
        >
          <p>Read more</p>
          <span className="arrow">
            <span className="straight"></span>
            <span className="left"></span>
            <span className="right"></span>
          </span>
        </div>
        <span className="transition bottom"></span>
      </section>
      <div ref={scrollToPatient}></div>
      <AboutPatients />
      <Healthcare />
    </div>
  );
};

export default AboutUsPage;
