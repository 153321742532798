import React from "react";
import Slider from "react-slick";
import aetna from "../assets/img/healthcare-logos/Aetna.jpg";
import evernorth from "../assets/img/healthcare-logos/Evernorth.jpg";
import medicare from "../assets/img/healthcare-logos/Medicare.jpg";
import carefirst from "../assets/img/healthcare-logos/carefirst.png";
import hopkins from "../assets/img/healthcare-logos/hopkins.jpg";
import optum from "../assets/img/healthcare-logos/optum.jpeg";
import "../styles/healthcare.scss";

const Healthcare = () => {
  const logos = [
    { name: "aetna", img: aetna },
    { name: "carefirst", img: carefirst },
    { name: "evernorth", img: evernorth },
    { name: "johns hopkins healthcare", img: hopkins },
    { name: "medicare", img: medicare },
    { name: "optum, united behavioral health", img: optum },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Set the duration for each slide
  };

  return (
    <div className="healthcare-container">
      <h2>We Accept Insurance</h2>
      <div className="carousel-container">
        <Slider {...settings}>
          {logos.map((logo, logoIndex) => (
            <div className="slide" key={logoIndex}>
              <img src={logo.img} alt={logo.name} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Healthcare;
