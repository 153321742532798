import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import birds from "../../assets/img/birds.png";
import corner from "../../assets/img/corner-plants.png";
import knot from "../../assets/img/dark-knot.png";
import mark from "../../assets/img/dark-mark.png";
import snail from "../../assets/img/dark-snail.png";
import servicesImg from "../../assets/img/hands.png";
import aboutUsImg from "../../assets/img/woman-portrait.png";
import "../../styles/home.scss";
import Healthcare from "../healthcare";

const HomePage = () => {
  const [showForeground, setShowForeground] = useState(false);
  const landingRef = useRef(null);
  const foregroundRef = useRef(null);
  const descriptionRef = useRef(null);
  const scrollTo = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const landingBottom = landingRef.current.getBoundingClientRect().bottom;
      const descriptionTop = descriptionRef.current.getBoundingClientRect().top;

      // Check if the landing section is still visible on the screen
      if (landingBottom > 0 && descriptionTop > 0) {
        setShowForeground(true);
      } else {
        setShowForeground(false);
      }
    };

    const handleSticky = () => {
      if (foregroundRef.current) {
        const landingBottom = landingRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        // Check if the landing section is at the bottom of the viewport
        if (landingBottom <= windowHeight) {
          foregroundRef.current.style.position = "absolute";
          foregroundRef.current.style.bottom = 0;
        } else {
          foregroundRef.current.style.position = "fixed";
          foregroundRef.current.style.bottom = 0;
        }
      }
    };

    // Initial check on mount
    handleScroll();
    handleSticky();

    // Add scroll event listeners
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleSticky);

    return () => {
      // Cleanup: Remove the event listeners when the component is unmounted
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleSticky);
    };
  }, []); // Empty dependency array means the effect runs once after the initial render

  useEffect(() => {
    Aos.init({ duration: 2400 });
  }, []);

  return (
    <div className="home-page">
      <section className="home-landing" ref={landingRef}>
        {showForeground && (
          <div className="foreground" ref={foregroundRef}>
            <img src={birds} alt="" className="front-img birds" />
            <img src={corner} alt="" className="front-img corner" />
          </div>
        )}
        <div className="part one" id="section-1">
          <div className="circles">
            <img src={knot} alt="" data-aos="fade" data-aos-delay="500" />
            <img src={snail} alt="" data-aos="fade" data-aos-delay="1000" />
            <img src={mark} alt="" data-aos="fade" data-aos-delay="1500" />
          </div>
          <div
            className="down-btn"
            data-aos="fade"
            data-aos-delay="2500"
            onClick={() => {
              scrollTo.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <p>Scroll down</p>
            <span className="arrow">
              <span className="straight"></span>
              <span className="left"></span>
              <span className="right"></span>
            </span>
          </div>
        </div>
        <div className="part two" data-aos="fade" ref={scrollTo} id="section-2">
          <div className="text-content">
            <h1>Begin Your Healing</h1>
            <p data-aos="fade" className="text">
              Embark on a transformative journey with us. Begin your healing and
              discover personalized support, compassionate care, and a pathway
              to a brighter, healthier future. Learn more about our
              comprehensive psychiatric services and choose a partner dedicated
              to your well-being.
            </p>
          </div>
          <div
            className="down-btn"
            data-aos="fade"
            data-aos-delay="500"
            onClick={() => {
              descriptionRef.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <p>Learn more</p>
            <span className="arrow">
              <span className="straight"></span>
              <span className="left"></span>
              <span className="right"></span>
            </span>
          </div>
        </div>
      </section>
      <section className="menu" ref={descriptionRef} id="section-3">
        <div className="about-us multi-content">
          <img src={aboutUsImg} alt="" />
          <div className="content-container">
            <h2>Discover Who We Are</h2>
            <p>
              Step into the world of Boring Psychiatric Services LLC, where your
              well-being is our top priority. Explore our credentials and the
              services we offer. Discover a network of health insurance
              providers ensuring that your journey to mental wellness is
              supported. Unveil more about our approach and let us be your
              reassuring guide in your journey to mental well-being.
            </p>
            <button onClick={() => navigate("/about-us")}>Read more</button>
          </div>
        </div>
        <div className="services multi-content">
          <div className="content-container">
            <h2>Explore Our Services</h2>
            <p>
              Discover the care at Boring Psychiatric Services LLC – simple,
              personalized, and just for you. Learn about our services, tailored
              to support conditions like depression, bipolar disorders, anxiety,
              autism and more. Take a closer look at how we can make your mental
              well-being journey comfortable and unique. Explore more about us
              and find a welcoming space for your needs.
            </p>
            <button onClick={() => navigate("/services")}>Learn more</button>
          </div>
          <img src={servicesImg} alt="" />
        </div>
        <div className="contact">
          <h2>Connect with Us Today</h2>
          <p>
            Ready to take the first step toward well-being? Schedule an
            appointment with us or reach out with any concerns. Your journey to
            mental health starts with a simple call or message – we're here for
            you.
          </p>
          <button onClick={() => navigate("/contact")}>See contact</button>
        </div>
      </section>
      <Healthcare />
    </div>
  );
};

export default HomePage;
