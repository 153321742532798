import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef } from "react";
import phone from "../../assets/img/call_FILL0_wght400_GRAD0_opsz24.svg";
import fax from "../../assets/img/fax_FILL0_wght400_GRAD0_opsz24.svg";
import location from "../../assets/img/location_on_FILL0_wght400_GRAD0_opsz24.svg";
import mail from "../../assets/img/mail_FILL0_wght400_GRAD0_opsz24.svg";
import customMarker from "../../assets/img/marker-icon.png";
import "../../styles/contact.scss";

const ContactPage = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map("map").setView([39.52977, -76.822403], 12);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapRef.current);

      const customIcon = L.icon({
        iconUrl: customMarker,
        iconAnchor: [22, 75], // Adjust the anchor point to center the marker vertically
      });

      L.marker([39.52977, -76.822403], { icon: customIcon }).addTo(
        mapRef.current
      );
    }
  }, []);

  return (
    <div className="contact-page">
      <div className="infos-container">
        <div className="appointment">
          <h2>Appointment Scheduling</h2>
          <p>
            To schedule an appointment or inquire about our services, please
            {"  "}
            <b>call</b> our office or <b>email</b> us. We look forward to
            supporting you on your journey to mental wellness.
          </p>
          <p>Your mental health matters. Let us help you thrive.</p>
          <p className="signature">Gayle Blum, PMHNP-BC, FNP-BC</p>
          <p className="signature">
            Psychiatric Nurse Practitioner at Boring Psychiatric Services LLC
          </p>
          <div id="map" className="Map"></div>
        </div>
        <div className="infos">
          <ul>
            <li className="icon-text">
              <img src={location} alt="" /> <p>Address :</p>
            </li>
            <li>
              <p className="bold">Boring Psychiatric Services LLC</p>
            </li>
            <li>
              <p className="bold">14707 Old Hanover Road</p>
            </li>
            <li>
              <p className="bold">Boring, Maryland 21020</p>
            </li>
            <li className="icon-text">
              <img src={phone} alt="" />
              <p>Phone :</p>
            </li>
            <li>
              <p className="bold">(410) 505-8094</p>
            </li>
            <li className="icon-text">
              <img src={fax} alt="" /> <p>Fax :</p>
            </li>
            <li>
              <p className="bold">(410) 987-2124</p>
            </li>
            <li className="icon-text">
              <img src={mail} alt="" /> <p>Email :</p>
            </li>
            <li>
              <p className="bold">info@BoringPsych.com</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
