import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/boringpsy-logo-large.png";
import "../styles/footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="logo">
          <img src={logo} alt="Boring Psychiatry services LLC" />
        </div>
        <div className="contact">
          <h3>Contact</h3>
          <p>
            Boring Psychiatric Services LLC
            <br />
            14707 Old Hanover Road
            <br />
            Boring, Maryland 21020
          </p>
          <Link to={"/contact"}>
            <button>
              <p>Contact Us Today</p>
            </button>
          </Link>
        </div>
      </div>
      <p className="made-by">
        Website made with ❤️ by{" "}
        <a href="https://boring.tech/" target="_blank" rel="noreferrer">
          Lola Raine
        </a>
      </p>
    </div>
  );
};

export default Footer;
